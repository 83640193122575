import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/Login'
  },
  {
    path: '/Login',
    name: 'Login',
    component: () => import('../views/Login/login.vue')
  },
  {
    path: '/Home',
    name: 'Home',
    redirect: '/User',
    component: () => import('../views/Home/index.vue'),
    children: [
      {
        path: '/User',
        name: 'User',
        component: () => import('../views/User/index.vue')
      },
      {
        path: '/Data',
        name: 'Data',
        component: () => import('../views/Data/index.vue')
      },
      {
        path: '/SyVersion',
        name: 'SyVersion',
        component: () => import('../views/Version/SyVersion.vue')
      },
      {
        path: '/VerInform',
        name: 'VerInform',
        component: () => import('../views/Version/VerInform.vue')
      },
      {
        path: '/Log',
        name: 'Log',
        component: () => import('../views/Log/index.vue')
      },
    ]
  }
]

const router = new VueRouter({
  routes
})

const originalPush = VueRouter.prototype.push
// 修改原型对象中的push方法
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router
