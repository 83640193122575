import axios from 'axios'
import router from '../router/index'
import md5 from 'js-md5'
import Vue from 'vue'
import { Message } from 'element-ui'

axios.defaults.withCredentials = true
axios.defaults.timeout = 30000
//本地
// axios.defaults.baseURL = 'http://192.168.1.200:100'
//生产环境
axios.defaults.baseURL = 'https://admin.dazhihui029.com:8704'

// 请求拦截
axios.interceptors.request.use(config => {
  config.headers.Authorization = window.localStorage.getItem('token')
  config.headers.Loginsource = '1'
  config.headers.sign = getPwdStr(config.data)
  if (window.localStorage.getItem('loginInfo') !== null && window.localStorage.getItem('loginInfo') !== '') {
    config.headers.Anotherplace = JSON.parse(window.localStorage.getItem('loginInfo')).anotherplace
  }
  return config
}, error => {
  return Promise.reject(error)
})

// 响应拦截
axios.interceptors.response.use(function (response) {
  if (response.data.status === '3') {
    Message({ message: response.data.message, type: 'error' })
    setTimeout(() => {
      router.replace({ name: 'Login' })
    }, 1000)
  }
  if (response.data.status === '2') {
    Message({ message: response.data.message, type: 'error' })
    setTimeout(() => {
      router.replace({ name: 'Login' })
    }, 1000)
  }
  return response
}, function (error) {
  if (error.response) {
    Message.error('服务器繁忙！')
    // if (error.response.status === 403) {
    //   Message.error('服务器繁忙')
    // } else if (error.response.status === 404) {
    //   Message.error('请求错误')
    // } else if (error.response.status === 500) {
    //   Message.error('服务器错误')
    // } else if (error.response.status >= 500) {
    //   Message.error('服务器异常')
    // }
  }
  return Promise.reject(error)
})

// get请求
export function get (url, params) {
  return new Promise((resolve, reject) => {
    axios.get(url, {
      params: params
    }).then(res => {
      resolve(res.data)
    }).catch(err => {
      reject(err.data)
      Message({ message: '服务器繁忙！请稍后重试', type: 'error' })
    })
  })
}

// post请求
export function post (url, params) {
  return new Promise((resolve, reject) => {
    axios.post(url, getKey(params))
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        reject(err.data)
        Message({ message: '服务器繁忙！请稍后重试', type: 'error' })
      })
  })
}

// put请求
export function put (url, params) {
  return new Promise((resolve, reject) => {
    axios.put(url, params)
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        reject(err.data)
        Message({ message: '服务器繁忙！请稍后重试', type: 'error' })
      })
  })
}

// delete 请求
export function deletefn (url, params) {
  return new Promise((resolve, reject) => {
    axios.delete(url, {
      data: params
    }).then(res => {
      resolve(res.data)
    }).catch(err => {
      reject(err.data)
      Message({ message: '服务器繁忙！请稍后重试', type: 'error' })
    })
  })
}

export function getKey (param) {
  let _data = {}
  let str = ''
  if (param === undefined) {
    str += "key=zkpfw*%$dazhihui029@sdko34@%"
  } else {
    _data = sort_ASCII(param)
    for(let i in _data){
      /* 1、判断value不为空，用&拼起来 */
      if (_data[i] !== ""){
        str += i + "=" + JSON.stringify(_data[i]) + "&"
      }
    }
    str += "key=zkpfw*%$dazhihui029@sdko34@%"
  }
  // _data['pwdKey'] = md5(str).toUpperCase()
  /* 2、拼接key */
  // console.log(_data)
  return _data
}
export function getPwdStr(data) {
  let str = ''
  if (data === {}) {
    str += "key=zkpfw*%$dazhihui029@sdko34@%"
  } else { /* 1、判断value不为空，用&拼起来 */
    for(let i in data){
      if (data[i] !== ""){
        if (typeof data[i] === 'string') {
          str += i + "=" + data[i] + "&"
        } else if (typeof data[i] === 'number') {
          str += i + "=" + String(data[i]) + "&"
        } else if(typeof data[i] === 'object') {
          str += i + "=" + JSON.stringify((data[i])) + "&"
        }
      }
    }
    str += "key=zkpfw*%$dazhihui029@sdko34@%"
  }
  // console.log(str)
  return md5(str).toUpperCase()
}
/* 对象排序 */
export function sort_ASCII(obj){
  var arr = new Array()
  var num = 0
  for (var i in obj) {
    arr[num] = i
    num++
  }
  var sortArr = arr.sort()
  var sortObj = {}
  for (var i in sortArr) {
    sortObj[sortArr[i]] = obj[sortArr[i]]
  }
  return sortObj
}
