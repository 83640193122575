import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import './http/http.js'
import 'element-ui/lib/theme-chalk/index.css'
import './assets/css/common.css'

Vue.config.productionTip = false
//本地
// Vue.prototype.BASE_URL = 'http://192.168.1.200:100'
//生产环境
Vue.prototype.BASE_URL = 'https://admin.dazhihui029.com:8704'

Vue.use(ElementUI)

// 路由导航守卫
// router.beforeEach((to, from, next) => {
//   if (to.path === '/Login') return next()
//   const tokenStr = window.sessionStorage.getItem('loginInfo')
//   if (!tokenStr) return next('/Login')
//   next()
// })

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
